import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Observable, filter, pairwise } from 'rxjs';

@Injectable()
export class UrlService {
  last_externSearchQueryParams: string;
  private _externSearchQueryParams: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_externSearchQueryParams(): Observable<any> {
    return this._externSearchQueryParams.asObservable();
  }

  last_externSelectedIdsQueryParams: string;
  private _externSelectedIdsQueryParams: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public get get_externSelectedIdsQueryParams(): Observable<number[]> {
    return this._externSelectedIdsQueryParams.asObservable();
  }

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParamMap.subscribe((paras) => {
      const raw_search = paras.get('s');
      if (raw_search !== null && raw_search !== undefined && raw_search.length > 0) {
        const search = decodeURIComponent(atob(raw_search));
        if (raw_search !== this.last_externSearchQueryParams) {
          this._externSearchQueryParams.next(JSON.parse(search));
          this.last_externSearchQueryParams = raw_search;
        }
      } else {
        this._externSearchQueryParams.next(null);
      }

      //-----

      const raw_ids = paras.get('i');
      if (raw_ids !== null && raw_ids !== undefined && raw_ids.length > 0) {
        const idStringArray = raw_ids.split(',');
        const idArray = idStringArray.map((x) => +x);

        if (raw_ids !== this.last_externSelectedIdsQueryParams) {
          this._externSelectedIdsQueryParams.next(idArray);
          this.last_externSelectedIdsQueryParams = raw_search;
        }
      } else {
        this._externSelectedIdsQueryParams.next([]);
      }
    });
  }

  naviToMergeQueryParams(path: string, queryParams: any) {
    this.router.navigate([path], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  naviToPreserveQueryParams(path: string) {
    this.router.navigate([path], { queryParamsHandling: 'preserve' });
  }

  naviTo(path: string, queryParams: any) {
    this.router.navigate([path], { queryParams: queryParams });
  }

  refreshQueryParams(queryParams: any) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  private history = [];

  loadRouting(): void {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.history = [...this.history, events[1].urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/depart/start';
  }
}
