<dx-popup
  #popup
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  title="EWO-Suche"
  width="1200px"
  height="760px"
>
  <div *dxTemplate="let data of 'content'">
    <dx-form [formData]="contextData.searchData">
      <dxi-item itemType="group" caption="Suchkriterien" [colCount]="6" cssClass="titel-ergebnisse">
        <dxi-item [label]="{ text: 'Name' }" [colSpan]="2">
          <dx-text-box #nachnameTextbox [(value)]="contextData.searchData.nachname" [maxLength]="100" valueChangeEvent="keyup"> </dx-text-box>
        </dxi-item>

        <dxi-item [label]="{ text: 'Vorname' }" [colSpan]="2">
          <dx-text-box [(value)]="contextData.searchData.vorname" [maxLength]="100" valueChangeEvent="keyup"> </dx-text-box>
        </dxi-item>
        <dxi-item
          dataField="geburtsdatum"
          [label]="{ text: 'Geburtsdatum' }"
          editorType="dxDateBox"
          [editorOptions]="{ type: 'date', valueChangeEvent: 'keyup' }"
        >
        </dxi-item>
        <dxi-item itemType="empty"></dxi-item>

        <dxi-item dataField="strasse" [label]="{ text: 'Straße' }" [colSpan]="4">
          <div *dxTemplate>
            <app-autocompletebox-osm
              #ac
              [valueType]="'street'"
              [placeholder]="'Zum Suchen Straße, PLZ, Ort eingeben...'"
              [value]="contextData.searchData.strasse"
              [centerLat]="addressLat"
              [centerLng]="addressLon"
              (onSelected)="onAddrKindSelected($event)"
              (onChange)="onChangeStrasseKind($event)"
            >
            </app-autocompletebox-osm>
          </div>
        </dxi-item>

        <dxi-item
          dataField="hnr"
          [label]="{ text: 'Nr.' }"
          editorType="dxNumberBox"
          [editorOptions]="{ showClearButton: true, showSpinButtons: false, valueChangeEvent: 'keyup' }"
          [colSpan]="1"
        >
        </dxi-item>

        <dxi-item dataField="hnrZusatz" [label]="{ text: 'Zusatz' }" [editorOptions]="{ maxLength: 5 }" [colSpan]="1"></dxi-item>

        <dxi-item dataField="plz" [label]="{ text: 'PLZ' }" [editorOptions]="{ maxLength: 5, valueChangeEvent: 'keyup' }" [colSpan]="1" [colSpan]="1">
        </dxi-item>

        <dxi-item dataField="ort" [editorOptions]="{ maxLength: 50, valueChangeEvent: 'keyup' }" [colSpan]="3"> </dxi-item>
        <dxi-item dataField="ortsteil" [editorOptions]="{ maxLength: 50 }" [colSpan]="2"> </dxi-item>
        <dxi-item itemType="empty" [colSpan]="4"></dxi-item>
        
        <dxi-item cssClass="button-align-right">
          <dx-button stylingMode="contained" type="default" text="Suchen" [icon]="'find'" (onClick)="onClickEwoFind()"></dx-button>
        </dxi-item>
        <dxi-item cssClass="button-align-right">
          <dx-button stylingMode="contained" type="default" text="Alle zurücksetzen" [icon]="'undo'" (onClick)="onClickEwoClear()"></dx-button>
        </dxi-item>
      </dxi-item>
      <dxi-item itemType="group" caption="Ergebnisse" [colSpan]="6" cssClass="titel-ergebnisse"> </dxi-item>
    </dx-form>

    <!-- [selectedRowKeys]="selectedItem !== undefined && selectedItem !== null ? [selectedItem.id] : []" -->
    <dx-data-grid
      #dataGrid
      [dataSource]="dataSource"
      height="287px"
      width="1160px"
      (onSelectionChanged)="onSelectionChanged($event)"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      (onContentReady)="onContentReady($event)"
      [(focusedRowKey)]="focusedRowKey"
      [autoNavigateToFocusedRow]="true"
      (onRowPrepared)="onRowPrepared($event)"
    >
      <dxo-remote-operations [filtering]="true" [paging]="true" [sorting]="true" [summary]="true" [grouping]="true" [groupPaging]="true">
      </dxo-remote-operations>

      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="ewoPersonSearchDataGrid1"> </dxo-state-storing>

      <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

      <dxo-selection selectAllMode="page" mode="single"></dxo-selection>

      <dxo-paging [pageSize]="30"></dxo-paging>

      <dxo-header-filter [visible]="false"></dxo-header-filter>
      <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>

      <dxo-search-panel [width]="300" [visible]="true"> </dxo-search-panel>

      <dxi-column [width]="200" dataField="name.name1" caption="Nachname"></dxi-column>
      <dxi-column [width]="200" dataField="vornamen" caption="Vorname"></dxi-column>

      <!-- <dxi-column [width]="120" dataField="geburtsdaten.geburtsdatum" caption="Geburtsdatum" dataType="string"> </dxi-column> -->
      <dxi-column [width]="120" dataField="gebdat" caption="Geburtsdatum" dataType="date" format="dd.MM.yyyy"> </dxi-column>

      <dxi-column [width]="200" dataField="geschlecht" caption="Geschlecht" [customizeText]="getGeschlechtText"></dxi-column>
      <dxi-column [width]="200" dataField="wohnungen" caption="Adresse" [customizeText]="getAdressText" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

      <!-- <dxi-column [width]="100" dataField="geschlechtId" caption="Geschlecht">
                <dxo-lookup [dataSource]="configService.getKatalog('GeschlechtKatalog')" displayExpr="bezeichnung"
                    valueExpr="id">
                </dxo-lookup>
            </dxi-column> -->
    </dx-data-grid>
  </div>

  <!-- ------------------ -->
  <dxi-toolbar-item
    *ngIf="contextData && contextData.showAddSorgeberechtigte"
    widget="dxCheckBox"
    location="after"
    toolbar="bottom"
    [options]="{
      text: 'Sorgeberechtigte übernehmen',
      type: 'default',
      stylingMode: 'contained',
      icon: 'add',
      onValueChanged: onSelectAddSorgeberechtigte
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{
      disabled: isLoading || selectedItem === undefined || selectedItem === null,
      text: 'Übernehmen',
      type: 'default',
      stylingMode: 'contained',
      icon: 'check',
      onClick: onClickOk
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }"
  >
  </dxi-toolbar-item>
</dx-popup>

<app-columnChooser *ngIf="init" #columnChooser [dataGrid]="dataGrid"></app-columnChooser>
