<div class="app-content" *ngIf="!isInit">
    Initialisiere Anwendung!
</div>

<div class="app-content" *ngIf="isInit">
    <router-outlet></router-outlet>
</div>

<app-checkInquirePopup *ngIf="isInit"></app-checkInquirePopup>
<app-vorlagenPopup *ngIf="isInit"></app-vorlagenPopup>
<app-wiedervorlagenGridPopup *ngIf="isInit"></app-wiedervorlagenGridPopup>
<app-wiedervorlagenEditPopup *ngIf="isInit"></app-wiedervorlagenEditPopup>
<app-docX-multi-preview-popup type="Antrag" #multiRichEditPopup></app-docX-multi-preview-popup>
<app-loginCodePopup *ngIf="isInit"></app-loginCodePopup>
<app-bgb-person-search-popup *ngIf="isInit"></app-bgb-person-search-popup>
<app-stapelBearbeitungPopup *ngIf="isInit"></app-stapelBearbeitungPopup>
<app-ewo-person-search-popup *ngIf="isInit"></app-ewo-person-search-popup>
<app-bgb-stapelberechnung-dialog *ngIf="isInit"></app-bgb-stapelberechnung-dialog>

