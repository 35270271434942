<div class="menu">
    <div *ngFor="let menu of menuItems">
        <div *ngIf="menu.items.length === 0" class="menu-item" (click)="onClickMenuItem($event, menu)"
            [ngClass]="{'selected-item': menu.isSelected, 'has-info': menu.hasInfo}" [attr.data-info-text]="menu.infoText">
            <div [ngClass]="{'has-error': menu.hasError}"></div>
            {{menu.name}}
        </div>

        <!-- <div *ngIf="menu.items.length > 0" class="menu-item" (click)="onClickMenuWithSubItems($event, menu)" [ngClass]="{'selected-item': menu.isSelected}">
            <div class="menu-item-wrapper menu-item-wrapper-closed noselect" id="wrapper-{{menu.key}}-closed">
                <div><i class="dx-icon-chevronright">&nbsp;</i> {{menu.name}}</div>
                <div [ngClass]="{'has-error': menu.hasError}"></div>
                <div [ngClass]="{'has-info': menu.hasInfo}"></div>
            </div>

            <div class="menu-item-wrapper menu-item-wrapper-open noselect" id="wrapper-{{menu.key}}-open">
                <div><i class="dx-icon-chevrondown">&nbsp;</i> {{menu.name}}</div>
                <div [ngClass]="{'has-error': menu.hasError}"></div>
                <div [ngClass]="{'has-info': menu.hasInfo}"></div>
            </div>
        </div> -->

        <div *ngIf="menu.items.length > 0" class="menu-item" (click)="onClickMenuWithSubItems($event, menu)" [ngClass]="{'selected-item': menu.isSelected}">
          <div *ngIf="!menu.isOpen" class="menu-item-wrapper menu-item-wrapper-closed noselect" id="wrapper-{{menu.key}}-closed">
              <div><i class="dx-icon-chevronright">&nbsp;</i> {{menu.name}}</div>
              <div [ngClass]="{'has-error': menu.hasError}"></div>
              <div [ngClass]="{'has-info': menu.hasInfo}"></div>
          </div>

          <div *ngIf="menu.isOpen" class="menu-item-wrapper menu-item-wrapper-open noselect" id="wrapper-{{menu.key}}-open">
              <div><i class="dx-icon-chevrondown">&nbsp;</i> {{menu.name}}</div>
              <div [ngClass]="{'has-error': menu.hasError}"></div>
              <div [ngClass]="{'has-info': menu.hasInfo}"></div>
          </div>
        </div>        

        <div *ngIf="menu.isOpen" class="menu-item-subitem-container closed noselect" id={{menu.key}}>
            <div *ngFor="let subItem of menu.items" class="submenu-item" 
            [ngClass]="{'selected-item': subItem.isSelected, 'has-info-subitem': subItem.hasInfo}" [attr.data-info-text]="subItem.infoText"
             (click)="onClickSubmenuItem($event, menu, subItem)">
             <div [ngClass]="{'has-error': subItem.hasError}"></div>
                <i *ngIf="subItem.icon !== undefined && subItem.icon.length > 0" class="dx-icon-add">&nbsp;</i>{{subItem.name}}
            </div>
        </div>
    </div>
</div>

