import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { TableContainerComponent } from '../components/table-container/table-container.component';
import { DxResponsiveBoxModule } from 'devextreme-angular/ui/responsive-box';
import { DxBoxModule } from 'devextreme-angular/ui/box';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { DxBulletModule } from 'devextreme-angular/ui/bullet';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxSortableModule } from 'devextreme-angular/ui/sortable';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxAutocompleteModule } from 'devextreme-angular/ui/autocomplete';

import { ColumnChooserComponent } from '../components/columnChooser/columnChooser.component';
import { EditPasswordPopupComponent } from 'src/app/department/department-profile/editPasswordPopup/editPasswordPopup.component';
import { VorlagenPopupComponent } from '../components/vorlagenPopup/vorlagenPopup.component';
import { DxGalleryModule } from 'devextreme-angular/ui/gallery';
import { DxHtmlEditorModule } from 'devextreme-angular/ui/html-editor';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { WiedervorlagenEditPopupComponent } from '../components/wiedervorlagenEditPopup/wiedervorlagenEditPopup.component';
import { WiedervorlagenGridPopupComponent } from '../components/wiedervorlagenGridPopup/wiedervorlagenGridPopup.component';
import { DokumenteComponent } from '../components/dokumente/dokumente.component';
import { DokumenteEditComponent } from '../components/dokumente/dokumente-edit/dokumente-edit.component';
import { DxChartModule } from 'devextreme-angular/ui/chart';

import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { MenuSidebarComponent } from '../components/menu-sidebar/menu-sidebar.component';
import { ImprintComponent } from '../components/imprint/imprint.component';
import { PrivacyComponent } from '../components/privacy/privacy.component';
import { BilderComponent } from '../components/bilder/bilder.component';
import { BilderEditComponent } from '../components/bilder/bilder-edit/bilder-edit.component';
import { SecurePipe } from '../pipes/secure.pipe';
import { MapOsmComponent } from '../components/map-osm/map-osm.component';
import { AutocompleteboxOsmComponent } from '../components/autocompletebox-osm/autocompletebox-osm.component';
import { DxSliderModule } from 'devextreme-angular/ui/slider';
import { LoginCodePopupComponent } from '../components/loginCodePopup/loginCodePopup.component';
import { BgbPersonSearchPopupComponent } from '../components/bgb-person-search-popup/bgb-person-search-popup.component';
import { BgbPersonEditPopupComponent } from '../components/bgb-person-search-popup/bgb-person-edit-popup/bgb-person-edit-popup.component';
import { ErrorBoxComponent } from '../components/error-box/error-box.component';
import { StapelBearbeitungPopupComponent } from '../components/stapelBearbeitungPopup/stapelBearbeitungPopup.component';
import { DocxPreviewPopupComponent } from '../components/docX-preview-popup/docX-preview-popup.component';
import { DocxMultiPreviewPopupComponent } from '../components/docX-multi-preview-popup/docX-multi-preview-popup.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { EinkommensrechnerComponent } from '../components/einkommensrechner/einkommensrechner.component';
import { EinkommensrechnerSearchComponent } from '../components/einkommensrechner/einkommensrechner-search/einkommensrechner-search.component';
import { EwoPersonSearchPopupComponent } from '../components/ewo-person-search-popup/ewo-person-search-popup.component';
import { DxValidationGroupModule } from 'devextreme-angular';
import { NavigationStringComponent } from '../components/navigation-string/navigation-string.component';
import { SollstellungenKindComponent } from '../components/sollstellungen-kind/sollstellungen-kind.component';
import { BgbStapelberechnungDialogComponent } from '../components/bgb-stapelberechnung-dialog/bgb-stapelberechnung-dialog.component';

// import { AutocompleteboxTomtomComponent } from '../components/autocompletebox-tomtom/autocompletebox-tomtom.component';

@NgModule({
  declarations: [
    TableContainerComponent,
    ColumnChooserComponent,
    EditPasswordPopupComponent,
    VorlagenPopupComponent,
    WiedervorlagenGridPopupComponent,
    WiedervorlagenEditPopupComponent,
    DokumenteComponent,
    DokumenteEditComponent,
    MenuSidebarComponent,
    ImprintComponent,
    PrivacyComponent,
    BilderComponent,
    BilderEditComponent,
    SecurePipe,
    // AutocompleteboxTomtomComponent,
    MapOsmComponent,
    AutocompleteboxOsmComponent,
    LoginCodePopupComponent,
    BgbPersonSearchPopupComponent,
    BgbPersonEditPopupComponent,
    ErrorBoxComponent,
    StapelBearbeitungPopupComponent,
    DocxMultiPreviewPopupComponent,
    DocxPreviewPopupComponent,
    EinkommensrechnerComponent,
    EinkommensrechnerSearchComponent,
    EwoPersonSearchPopupComponent,
    NavigationStringComponent,
    SollstellungenKindComponent,
    BgbStapelberechnungDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    DxMenuModule,
    DxDrawerModule,
    DxListModule,
    DxFormModule,
    DxButtonModule,
    DxToolbarModule,
    DxDataGridModule,
    DxTemplateModule,
    DxNumberBoxModule,
    DxLoadIndicatorModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxTabPanelModule,
    DxPopupModule,
    DxScrollViewModule,
    DxFileUploaderModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxAccordionModule,
    DxResponsiveBoxModule,
    DxBoxModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxPieChartModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxHtmlEditorModule,
    DxValidatorModule,
    DxChartModule,
    DxBulletModule,
    DxGalleryModule,
    DxRadioGroupModule,
    DxTooltipModule,
    DxSortableModule,
    DxAutocompleteModule,
    DxPopoverModule,
    DxSliderModule,
    PdfJsViewerModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TableContainerComponent,
    ColumnChooserComponent,
    EditPasswordPopupComponent,
    VorlagenPopupComponent,
    WiedervorlagenGridPopupComponent,
    WiedervorlagenEditPopupComponent,
    DokumenteComponent,
    DokumenteEditComponent,
    MenuSidebarComponent,
    BilderComponent,
    BilderEditComponent,
    SecurePipe,
    // AutocompleteboxTomtomComponent,
    MapOsmComponent,
    AutocompleteboxOsmComponent,
    DocxMultiPreviewPopupComponent,
    DocxPreviewPopupComponent,
    BgbPersonSearchPopupComponent,
    BgbPersonEditPopupComponent,
    ErrorBoxComponent,
    EinkommensrechnerComponent,
    EinkommensrechnerSearchComponent,
    NavigationStringComponent,
    SollstellungenKindComponent,

    DxMenuModule,
    DxDrawerModule,
    DxListModule,
    DxFormModule,
    DxButtonModule,
    DxToolbarModule,
    DxDataGridModule,
    DxTemplateModule,
    DxNumberBoxModule,
    DxLoadIndicatorModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxTabPanelModule,
    DxPopupModule,
    DxScrollViewModule,
    DxFileUploaderModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxAccordionModule,
    DxResponsiveBoxModule,
    DxBoxModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxPieChartModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxHtmlEditorModule,
    DxValidatorModule,
    DxChartModule,
    DxBulletModule,
    DxGalleryModule,
    DxRadioGroupModule,
    DxTooltipModule,
    DxSortableModule,
    DxAutocompleteModule,
    DxPopoverModule,
    DxSliderModule,
    LoginCodePopupComponent,
    StapelBearbeitungPopupComponent,
    EwoPersonSearchPopupComponent,
    BgbStapelberechnungDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InjectorModule {
  static forRoot(): ModuleWithProviders<InjectorModule> {
    return {
      ngModule: InjectorModule,
      providers: [],
    };
  }
}
