<dx-data-grid
  class="sollstellungen-datagrid"
  #sollstellungenDataGrid
  [dataSource]="dataSource"
  height="auto"
  [columnAutoWidth]="true"
  [rowAlternationEnabled] = 'false'
  (onSelectionChanged)="onSelectionChanged($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onContentReady)="onContentReady($event)"
  (onCellPrepared)="onCellPrepared($event)">

  <dxo-search-panel [width]="300" [visible]="true"></dxo-search-panel>
  <dxo-grouping #expand [autoExpandAll]="false" expandMode="rowClick"></dxo-grouping>

  <dxi-column dataField="dummy" caption="Monat" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="monat" caption="Monat" [calculateCellValue]="calculateMonatJahr" [groupIndex]="0" groupCellTemplate="diffGroupTemplateEB" [allowSorting]="false">
  </dxi-column>
  <dxi-column *ngIf="type==='haushalt'" dataField="buchung.kind" caption="Kind" [groupIndex]="1" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="betreuungsumfang" caption="Betreuungsumfang" [customizeText]="customizeTextBlank" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="einkommen" caption="Einkommen" dataType="number" format="#,##0.00 €" [customizeText]="customizeTextBlank" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="buchung.betragTyp" caption="Beitragstyp" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="buchung.soll" caption="Berechnet (Soll)" dataType="number" format="#,##0.00 €" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="buchung.ist" caption="Gezahlt (Ist)" dataType="number" format="#,##0.00 €" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="buchung.diff" caption="Differenz" cellTemplate="diffCellTemplateEB" dataType="number" format="#,##0.00 €" [allowSorting]="false"></dxi-column>

  <dxo-summary [calculateCustomSummary]="calculateSummary">
    <dxi-group-item
      name="betreuungsumfang"
      column="betreuungsumfang"
      summaryType="custom"
      [showInGroupFooter]="false"
      [alignByColumn]="true"
      displayFormat="{0}"
    >    
    </dxi-group-item>    
    <dxi-group-item
      name="einkommen"
      column="einkommen"
      summaryType="custom"
      valueFormat="#,##0.00 €"
      [showInGroupFooter]="false"
      [alignByColumn]="true"
      displayFormat="{0}"
    >    
    </dxi-group-item>
    <dxi-group-item
      column="buchung.soll"
      summaryType="sum"
      valueFormat="#,##0.00 €"
      [showInGroupFooter]="false"
      [alignByColumn]="true"
      displayFormat="{0}"
    >
    </dxi-group-item>
    <dxi-group-item
      column="buchung.ist"
      summaryType="sum"
      valueFormat="#,##0.00 €"
      [showInGroupFooter]="false"
      [alignByColumn]="true"
      displayFormat="{0}"
    >
    </dxi-group-item>
    <dxi-group-item
      column="buchung.diff"
      summaryType="sum"
      valueFormat="#,##0.00 €"
      [showInGroupFooter]="false"
      [alignByColumn]="true"
      displayFormat="{0}"
      captionTemplate="diffGroupTemplateEB"
    >
    </dxi-group-item>
  </dxo-summary>
  <!-- <dxi-column caption="Berechnet" alignment="center">
    <dxi-column dataField="elternbeitrag.soll" caption="Elternbeitrag" dataType="number" format="#,##0.00 €"></dxi-column>
    <dxi-column dataField="essengeld.soll" caption="Verpflegung" dataType="number" format="#,##0.00 €"></dxi-column>
    <dxi-column dataField="ferienbetreuung.soll" caption="Ferienbetreuung" dataType="number" format="#,##0.00 €"></dxi-column>
  </dxi-column>
  <dxi-column caption="Gezahlt" alignment="center">
    <dxi-column dataField="elternbeitrag.ist" caption="Elternbeitrag" dataType="number" format="#,##0.00 €"></dxi-column>
    <dxi-column dataField="elternbeitrag.diff" caption="Diff." cellTemplate="diffCellTemplateEB" dataType="number" name="elternbeitrag"></dxi-column>
    <dxi-column dataField="essengeld.ist" caption="Verpflegung" dataType="number" format="#,##0.00 €"></dxi-column>
    <dxi-column dataField="essengeld.diff" caption="Diff." cellTemplate="diffCellTemplateEG" dataType="number" name="essengeld"></dxi-column>
    <dxi-column dataField="ferienbetreuung.ist" caption="Ferienbetreuung" dataType="number" format="#,##0.00 €"></dxi-column>
    <dxi-column dataField="ferienbetreuung.diff" caption="Diff." cellTemplate="diffCellTemplateFB" dataType="number" name="ferienbetreuung"></dxi-column>
  </dxi-column>   -->

  <div *dxTemplate="let d of 'diffCellTemplateEB'"
    [class]="d.data.buchung.diff === 0 ? 'null' : (d.data.buchung.diff > 0 ? 'inc' : 'dec')">
    <div class="diff">{{ abs(d.data.buchung.diff) | currency : "EUR" : 'symbol' : "1.2-2" : "de-DE"}}
    </div>
  </div>
  <div *dxTemplate="let d of 'diffGroupTemplateEB'">
    <div class="diff">{{ d.value}}
    </div>
  </div>  
</dx-data-grid>