import { Component, EventEmitter, Output, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { ApiService } from 'src/app/share/services/api.service';
import { PopupService } from '../../services/popup.service';
import { Subscription } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ConfigService } from '../../services/config.service';
import { AuthService } from '../../services/auth.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-stapelBearbeitungPopup',
  templateUrl: './stapelBearbeitungPopup.component.html',
  styleUrls: ['./stapelBearbeitungPopup.component.scss'],
})
export class StapelBearbeitungPopupComponent implements OnInit, OnDestroy {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;

  subscription_get_PopupAsObservable: Subscription;

  onClose: (e) => void;

  formData: any;
  statusKatalog: any[];
  statusSelected: boolean = true;
  isAblehungsgrundVisible: boolean = false;
  statusMitVorschlag: number[] = [];
  isStatusMitVorschlag: boolean = false;
  isLoading: boolean = false;
  isError: boolean = false;

  constructor(
    private apiService: ApiService,
    private popupService: PopupService,
    public authService: AuthService,
    public configService: ConfigService
  ) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onStatusChanged = this.onStatusChanged.bind(this);
    this.createEmptyFormData();
    this.statusSelected = true;

    this.subscription_get_PopupAsObservable = this.popupService.get_stapelBearbeitungPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true && context.data !== undefined && context.data !== null) {
        this.statusKatalog = this.configService.getKatalog('StatusKatalog');
        this.formData.antragIds = context.data;

        this.onClose = context.closeCb;
        this.popup.instance.show();
      } else {
        this.onClickClose();
      }
      this.statusMitVorschlag = [];
      this.statusMitVorschlag.push(this.configService.getStatusId('Vorgeschlagen'));
      this.statusMitVorschlag.push(this.configService.getStatusId('Vorschlag angenommen'));
      this.statusMitVorschlag.push(this.configService.getStatusId('Angenommen'));
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  onContentReady(e) {
    e.component.columnOption('command:select', {
      visibleIndex: 1,
      fixedPosition: 'right',
      visibleWidth: 70,
    });
  }

  onClickClose() {
    if (this.popup !== undefined && this.popup != null && this.popup.instance !== undefined && this.popup.instance != null) {
      if (this.onClose !== undefined && this.onClose !== null) {
        this.onClose(3);
      }
      this.createEmptyFormData();
      this.statusSelected = true;
      this.popup.instance.hide();
    }
  }

  onClickSave() {
    if (this.formData.statusId !== undefined && this.formData.statusId !== null) {
      this.isLoading = true;
      this.apiService
        .post<any>(RouteKeys.Inquire.stapelbearbeitung, this.formData)
        .toPromise()
        .then((response) => {
          this.isLoading = false;
          this.onClickClose();
        })
        .catch((err) => {
          this.isError = true;
          this.isLoading = false;
          throw err.message;
        });
    } else {
      this.statusSelected = false;
    }
  }

  getStatusClass(status) {
    if (status !== undefined && status !== null && status.id >= 0) {
      return 'status-select-icon-' + status.bezeichnung.replace(/\s/g, '').toLowerCase();
    } else {
      return 'status-select-icon-none';
    }
  }

  createEmptyFormData() {
    this.formData = {
      antragIds: [],
      statusId: null,
      sendMail: false,
      grundFuerAblehnungID: null,
    };
    this.isError = false;
  }

  onStatusChanged(e) {
    this.isAblehungsgrundVisible = this.formData.statusId === this.configService.getStatusId('Abgelehnt');
    this.isStatusMitVorschlag = this.statusMitVorschlag.includes(this.formData.statusId);
    this.statusSelected = true;
  }
}
