import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { INaviString } from 'src/app/share/interfaces/NaviString/INaviString';

@Component({
  selector: 'app-navigation-string',
  templateUrl: './navigation-string.component.html',
  styleUrls: ['./navigation-string.component.scss'],
})
export class NavigationStringComponent {
  @Input() naviString: INaviString[];

  constructor() {}
}
