import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  useCrypt: boolean = false;

  constructor() {
    this.useCrypt = !window.globalConfig.dev;
  }

  clearSession() {
    sessionStorage.clear();
  }

  clearFromSession(key: string) {
    if (!key || !key.length) {
      throw new Error('invalid key: ' + key);
    }

    sessionStorage.removeItem(this.encrypt(key));
  }

  clearFromLocale(key: string) {
    if (!key || !key.length) {
      throw new Error('invalid key: ' + key);
    }

    localStorage.removeItem(this.encrypt(key));
  }

  getFromSession<T>(key: string): T | null {
    if (!key || !key.length) {
      throw new Error('invalid key: ' + key);
    }

    const item = sessionStorage.getItem(this.encrypt(key));
    if (item) {
      return this.decrypt<T>(item);
    } else {
      return null;
    }
  }

  getFromLocale<T>(key: string): T | null {
    if (!key || !key.length) {
      throw new Error('invalid key: ' + key);
    }

    const item = localStorage.getItem(this.encrypt(key));
    if (item) {
      return this.decrypt<T>(item);
    } else {
      return null;
    }
  }

  private getFromLocaleUnencrypt<T>(key: string): T | null {
    if (!key || !key.length) {
      throw new Error('invalid key: ' + key);
    }

    const item = localStorage.getItem(key);
    if (item) {
      return <T>item;
    } else {
      return null;
    }
  }

  saveToSession(key: string, data: any) {
    if (!key || !key.length) {
      throw new Error('invalid key: ' + key);
    }

    if (data === null || data === undefined) {
      throw new Error('invalid data: ' + data);
    }

    sessionStorage.setItem(this.encrypt(key), this.encrypt(data));
  }

  saveToLocale(key: string, data: any) {
    if (!key || !key.length) {
      throw new Error('invalid key: ' + key);
    }

    if (data === null || data === undefined) {
      throw new Error('invalid data: ' + data);
    }

    localStorage.setItem(this.encrypt(key), this.encrypt(data));
  }

  private saveToLocaleUnencrypt(key: string, data: any) {
    if (!key || !key.length) {
      throw new Error('invalid key: ' + key);
    }

    if (data === null || data === undefined) {
      throw new Error('invalid data: ' + data);
    }
    localStorage.setItem(key, data);
  }

  private encrypt(data: any): string {
    if (this.useCrypt) {
      return btoa(encodeURIComponent(JSON.stringify(data)));
    } else {
      return JSON.stringify(data);
    }
  }

  private decrypt<T>(data: string): T {
    if (!data) {
      throw new Error('invalid data: ' + data);
    }

    if (this.useCrypt) {
      return JSON.parse(decodeURIComponent(atob(data)));
    } else {
      return JSON.parse(data) as T;
    }
  }

  setSelectedRowKey(key, value) {
    const item = this.getFromLocaleUnencrypt<any>(key);
    if (item !== null && item !== undefined && item.length > 0) {
      const item_object = JSON.parse(item);
      if (item_object !== null && item_object !== undefined) {
        item_object.selectedRowKeys = [value];
        item_object.focusedRowKey = value;
        this.saveToLocaleUnencrypt(key, JSON.stringify(item_object));
      }
    }
  }
}
