<dx-popup #popup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="true" title="Stapelbearbeitung" width="440" height="380" [disabled]="isLoading">
    <div *dxTemplate="let data of 'content'" class="popup-content">
        <span>
            Hier können Sie für <b>alle ausgewählten Anträge ({{formData.antragIds.length}})</b> einen neuen Status setzen.
        </span>
        <span class="danger-text">
            Achtung! Die Änderungen können nach dem Übernehmen nicht mehr zurückgesetzt werden!
        </span>
        <dx-form [formData]="formData" class="stapelbearbeitung-form">
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item [label]="{ text: 'Neuer Status'}" dataField="statusId" editorType="dxSelectBox"
                    [editorOptions]="{
                        dataSource: statusKatalog, 
                        valueExpr: 'id',
                        displayExpr: 'bezeichnung',
                        value: formData.statusId,
                        showClearButton: false,
                        itemTemplate: 'itemStatus',
                        fieldTemplate: 'fieldStatus',
                        onValueChanged: onStatusChanged
                    }">
                </dxi-item>         

                <dxi-item dataField="sendMail" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{ text: 'Info-Mail an den Bürger' }" >
                </dxi-item>                

                <dxi-item
                    [visible]="isAblehungsgrundVisible"
                    dataField="grundFuerAblehnungID"
                    editorType="dxSelectBox"
                    [label]="{ text: 'Grund der Ablehnung' }"
                    [colSpan]="2"
                    [editorOptions]="{
                        dataSource: this.configService.getKatalog('GrundFuerAblehnungKatalog'),
                        valueExpr: 'id',
                        displayExpr: 'bezeichnung'
                    }"
                >
                </dxi-item> 
                <dxi-item *ngIf="isStatusMitVorschlag && !isError" [colSpan]="2">
                    <div class="error-container">
                        <span class="danger-text danger-text-bold">
                            Achtung! Durch diese Aktion wird bei fehlendem Einrichtungs-Vorschlag dieser automatisch durch den Erstwunsch ersetzt.
                        </span>
                    </div>
                </dxi-item>
            </dxi-item>

            <div *dxTemplate="let data of 'itemStatus'">
                <div class="status-select-container">
                    <i class="dx-icon-isnotblank" [ngClass]="getStatusClass(data)"></i>
                    <span class="status-select-text">  {{data.bezeichnung}}</span>
                </div>
            </div>            
            <div *dxTemplate="let data of 'fieldStatus'">
                <div class="status-select-container">
                    <i class="dx-icon-isnotblank" [ngClass]="getStatusClass(data)"></i>
                    <dx-text-box class="status-select-box" [value]="data && data.bezeichnung"
                        [readOnly]="true"></dx-text-box>
                </div>
            </div>             
        </dx-form>
        <div class="error-container">
            <span *ngIf="!statusSelected">Sie müssen einen Status angeben um Speichern zu können!</span>
            <span *ngIf="isError"><b>Achtung! Der Status konnte nicht bei allen ausgewählten Anträgen geändert werden. Bitte wiederholen Sie den Vorgang bei Bedarf!</b></span>
        </div>

        <dx-load-panel
          #loadPanel
          shadingColor="rgba(0,0,0,0.4)"
          [(visible)]="isLoading"
          [position]="{ of: '#popup' }"
          [showIndicator]="true"
          [showPane]="true"
          [shading]="false"
          [hideOnOutsideClick]="false"
          message="Status wird geändert..."
        >
        </dx-load-panel>
    </div>

    
    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Übernehmen', type: 'default', stylingMode: 'contained', icon: 'check', onClick: onClickSave, disabled: isError }">
    </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Schließen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-toolbar-item>
</dx-popup>