import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { control, Icon, icon, latLng, map, marker, tileLayer } from 'leaflet';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';

export interface IMarkerOptions {
  lat: number;
  lon: number;
  title: string;
  draggable: boolean;
  iconUrl: string;
  popupDetails: string;
  onClick: (e) => void;
  onDragend: (e) => void;
}

interface IMarkerCOntainer {
  id: number;
  marker: L.Marker;
}

@Component({
  selector: 'app-map-osm',
  templateUrl: './map-osm.component.html',
  styleUrls: ['./map-osm.component.scss'],
})
export class MapOsmComponent implements AfterViewInit {
  @Input() centerImage: string;
  @Input() centerTitle: string;
  @Input() centerPopupDetails: string;
  @Input() centerLat: number;
  @Input() centerLng: number;
  @Input() zoom: number = 12;
  @Input() isDraggable: boolean = true;
  @Output() dragEnd: EventEmitter<any> = new EventEmitter();

  private _map: L.Map;
  private _center_marker: L.Marker;
  private _idIndex: number = 0;
  private _markerArray: IMarkerCOntainer[] = [];

  mapContainerName: string;

  constructor(private languageService: LanguageService, private apiService: ApiService) {
    this.mapContainerName = this.crateGuiid();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._initMap();
    }, 50);
  }

  addMarker(options: IMarkerOptions): number {
    const newId = this._getNewId();
    const marker = this._createMarker(options);
    marker.addTo(this._map);

    this._markerArray.push({
      id: newId,
      marker: marker,
    });

    return newId;
  }

  getAddressFromLatLon(lat: number, lon: number, cb: (addr: any) => void) {
    const url = `https://photon.komoot.io/reverse?lon=${lon}&lat=${lat}&lang=de`;
    this.apiService.get<any>(url).subscribe({
      next: (response) => {
        if (
          response !== undefined &&
          response !== null &&
          response.features !== undefined &&
          response.features !== null &&
          response.features.length > 0
        ) {
          const address = this._mapOsmToaddress(response.features[0]);
          if (cb) {
            cb(address);
          }
        } else {
          if (cb) {
            cb(null);
          }
        }
      },
      error: (err) => {
        throw err;
      },
    });
  }

  flyTo(lat: number, lon: number) {
    if (lat !== null && lon !== null) {
      this._center_marker.setLatLng([lat, lon]);
      this._map.flyTo([lat, lon]);
    }
  }

  setZoom(zoom: number) {
    this.zoom = zoom;
    this._map.setZoom(zoom);
  }

  removeMarker(id: number) {
    const exist = this._markerArray.find((x) => x.id === id);
    if (exist !== undefined && exist !== null) {
      exist.marker.remove();
    }
  }

  removeAllMarker() {
    this._markerArray.forEach((marker) => {
      marker.marker.remove();
    });
  }

  setMarkerIcon(id: number, iconUrl: string) {
    const exist = this._markerArray.find((x) => x.id === id);
    if (exist !== undefined && exist !== null) {
      if (exist.marker.getIcon().options.iconUrl !== iconUrl) {
        const icon: L.Icon = new Icon({ iconUrl: iconUrl });
        exist.marker.setIcon(icon);
      }
    }
  }

  refresh() {
    this._map.setZoom(this.zoom);
  }

  private _initMap(): void {
    this._map = map(this.mapContainerName, {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
      center: [this.centerLat, this.centerLng],
      zoom: this.zoom,
    });

    control.scale().addTo(this._map);

    this._createCenterMarker();
  }

  private _createCenterMarker() {
    let markerOptions: IMarkerOptions = {
      lat: this.centerLat,
      lon: this.centerLng,
      title: this.centerTitle,
      draggable: this.isDraggable,
      iconUrl: this.centerImage,
      popupDetails: this.centerPopupDetails,

      onClick: () => {},

      onDragend: (e) => {
        this.centerLng = e.target.getLatLng().lng;
        this.centerLat = e.target.getLatLng().lat;

        if (this._map !== undefined && this._map !== null) {
          this._map.flyTo([this.centerLat, this.centerLng]);
        }

        this.getAddressFromLatLon(this.centerLat, this.centerLng, (address) => {
          this.dragEnd.next(address);
        });
      },
    };

    this._center_marker = this._createMarker(markerOptions);
    this._center_marker.addTo(this._map);
  }

  private _createMarker(options: IMarkerOptions): L.Marker {
    return marker([options.lat, options.lon], {
      icon: icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: options.iconUrl || 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png',
      }),
      title: options.title,
      riseOnHover: true,
      draggable: options.draggable || false,
    })
      .on('click', options.onClick)
      .on('dragend', options.onDragend)
      .bindPopup(options.popupDetails)
      .openPopup();
  }

  _mapOsmToaddress(item) {
    if (item == undefined || item == null) {
      return null;
    }

    let result: any = {
      place_id: item.properties.osm_id,
      display_name: '',
      lon: item.geometry.coordinates[0],
      lat: item.geometry.coordinates[1],
      org: item.properties,
      district:
        item.properties.district !== undefined &&
        item.properties.district !== null &&
        item.properties.district.length > 0 &&
        item.properties.district !== item.properties.city
          ? item.properties.district
          : '',
      locality:
        item.properties.locality !== undefined &&
        item.properties.locality !== null &&
        item.properties.locality.length > 0 &&
        item.properties.locality !== item.properties.city &&
        item.properties.locality !== item.properties.district
          ? item.properties.locality
          : '',
    };

    const street = item.properties.street !== undefined ? item.properties.street : null;
    const housenumberString = item.properties.housenumber !== undefined ? item.properties.housenumber : null;
    const postcode = item.properties.postcode !== undefined ? item.properties.postcode : null;
    const city = item.properties.city !== undefined ? item.properties.city : null;
    const state = item.properties.state !== undefined ? item.properties.state : null;
    const name = item.properties.name !== undefined ? item.properties.name : null;

    switch (item.properties.type) {
      case 'street':
        result.display_name = name;
        result.street = name;

        if (housenumberString !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }

          let housenumber = housenumberString.match(/\d+/g);
          if (housenumber !== undefined && housenumber !== null && housenumber.length > 0) {
            housenumber = housenumber[0];
          } else {
            housenumber = null;
          }

          let housenumberSupplement = housenumberString.match(/[a-zA-Z]+/g);
          if (housenumberSupplement !== undefined && housenumberSupplement !== null && housenumberSupplement.length > 0) {
            housenumberSupplement = housenumberSupplement[0];
          } else {
            housenumberSupplement = null;
          }

          result.display_name += housenumberString;
          result.housenumber = housenumber;
          result.supplement = housenumberSupplement;
        } else {
          result.housenumber = null;
        }

        if (postcode !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ', ';
          }
          result.display_name += postcode;
          result.postcode = postcode;
        } else {
          result.postcode = null;
        }

        if (city !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }
          result.display_name += city;
          result.city = city;

          if (result.district !== undefined && result.district !== null && result.district.length > 0) {
            result.display_name += ' - ' + result.district;
          }
        } else if (state !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }
          if (result.district !== undefined && result.district !== null && result.district.length > 0 !== null) {
            result.city = result.district;
            result.display_name += result.district;
            if (result.locality !== undefined && result.locality !== null && result.locality.length > 0) {
              result.display_name += ' - ' + result.locality;
              result.district = result.locality;
            } else {
              result.district = '';
            }
          } else {
            result.display_name += state;
            result.city = state;
          }
        } else {
          result.city = null;
        }

        break;
      case 'house':
        if (street !== null) {
          result.display_name = street;
          result.street = street;
        } else {
          result.street = null;
        }

        if (housenumberString !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }

          let housenumber = housenumberString.match(/\d+/g);
          if (housenumber !== undefined && housenumber !== null && housenumber.length > 0) {
            housenumber = housenumber[0];
          } else {
            housenumber = null;
          }

          let housenumberSupplement = housenumberString.match(/[a-zA-Z]+/g);
          if (housenumberSupplement !== undefined && housenumberSupplement !== null && housenumberSupplement.length > 0) {
            housenumberSupplement = housenumberSupplement[0];
          } else {
            housenumberSupplement = null;
          }

          result.display_name += housenumberString;
          result.housenumber = housenumber;
          result.supplement = housenumberSupplement;
        } else {
          result.housenumber = null;
        }

        if (postcode !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ', ';
          }
          result.display_name += postcode;
          result.postcode = postcode;
        } else {
          result.postcode = null;
        }

        if (city !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }
          result.display_name += city;
          result.city = city;

          if (result.district !== undefined && result.district !== null && result.district.length > 0) {
            result.display_name += ' - ' + result.district;
          }
        } else if (state !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }
          if (result.district !== undefined && result.district !== null && result.district.length > 0 !== null) {
            result.city = result.district;
            result.display_name += result.district;
            if (result.locality !== undefined && result.locality !== null && result.locality.length > 0) {
              result.display_name += ' - ' + result.locality;
              result.district = result.locality;
            } else {
              result.district = '';
            }
          } else {
            result.display_name += state;
            result.city = state;
          }
        } else {
          result.city = null;
        }
        break;
      default:
        console.warn('Unbekannter Type: ' + item.properties.type, item);
        result = null;
        break;
    }

    return result;
  }

  private _getNewId() {
    this._idIndex++;
    return this._idIndex;
  }

  private crateGuiid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
