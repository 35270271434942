import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ISidebarMenu } from '../interfaces/SidebarMenu/ISidebarMenu';
import { IMenuItem } from '../interfaces/SidebarMenu/IMenuItem';

@Injectable({
  providedIn: 'root',
})
export class MenuSidebarService {
  private _sidebarMenu: BehaviorSubject<any> = new BehaviorSubject(null);
  sidebarMenuAsObservable: Observable<any> = this._sidebarMenu.asObservable();

  private _rerender: BehaviorSubject<any> = new BehaviorSubject(null);
  rerenderAsObservable: Observable<any> = this._rerender.asObservable();

  constructor() {}

  rerender() {
    this._rerender.next(true);
  }

  getSidebarMenuAsObservable(): Observable<any> {
    return this.sidebarMenuAsObservable;
  }

  createMenu(menu: ISidebarMenu) {
    this._sidebarMenu.next(menu);
  }

  changeMenu(data, itemUrl) {
    data.menuItems.forEach((item) => {
      if (item.path.url === itemUrl) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      if (item.items.length > 0) {
        item.items.forEach((subItem) => {
          if (subItem.path.url === itemUrl) {
            subItem.isSelected = true;
          } else {
            subItem.isSelected = false;
          }
        });
      }
    });
  }

  changeMenuByName(data, itemKey, itemName) {
    this.deselectAllMenuItems(data.menuItems);
    const thisItem = data.menuItems.find((x) => x.key === itemKey);
    if (thisItem) {
      thisItem.isSelected = true;
      if (thisItem.items.length > 0) {
        const thisSubItem = thisItem.items.find((y) => y.name === itemName);
        if (thisSubItem) {
          thisSubItem.isSelected = true;
        }
      }
    }
  }

  changeSubMenuByKey(data: IMenuItem[], subItemKey: string) {
    if (data !== null && data !== undefined) {
      let items = data.filter((x) => x.items.length > 0);
      if (items !== null && items !== undefined) {
        items.forEach((item) => {
          let subItem = item.items.find((x) => x.key === subItemKey);
          if (subItem !== null && subItem !== undefined) {
            this.deselectAllMenuItems(data);
            subItem.isSelected = true;
            item.isSelected = true;
            return;
          }
        });
      }
    }
  }

  clickMenuWithSubMenu(menu: ISidebarMenu, item: IMenuItem) {
    item.isOpen = !item.isOpen;
    this._sidebarMenu.next(menu);
  }

  deselectAllMenuItems(menuItems: IMenuItem[]) {
    menuItems.forEach((item) => {
      item.isSelected = false;
      if (item.items.length > 0) {
        item.items.forEach((subItem) => {
          subItem.isSelected = false;
        });
      }
    });
  }
}
